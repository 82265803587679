import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

import Hero from "../components/hero";

import Features from "../components/features";
import Pricing from "../components/pricing";

function IndexPage() {
  return (
    <Layout>
      <SEO title="Visite virtuelle et comunication digitale" />
      <Hero />
      <Features />
      <Pricing/>
    </Layout>
  );
}

export default IndexPage;
