
import React from "react";
import InfographieService from '../images/infographie-services.jpg';

function Features() {

  return (
<div className="py-12 bg-white">
  <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
    <div className="md:text-center">
      <p className="text-base leading-6 text-red-600 font-semibold tracking-wide uppercase">Qu&apos;est ce que la visite virtuelle 3D Matterport</p>
      <h3 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
        La meilleur solution de visite virtuelle 3D
      </h3>
      <p className="mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
        La technologie Matterport® est une solution tout-en-un qui permet de créer des visites virtuelles en 3D réalistes et interactives.
      </p>
    </div>

    <div className="relative mt-4 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
      <div className="relative">     
      <ul className="mt-8 text-base">

          <li className="mt-4 flex items-start">
              <div className="flex-shrink-0 mr-2">
                  <svg viewBox="0 0 20 20" fill="currentColor" className="h-8 w-8 text-red-500">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                  </svg>
              </div>
              <p className="text-lg leading-8 font-medium text-gray-900">
              Visite virtuelle 3D
              </p>
          </li>

          <li className="mt-4 flex items-start">
              <div className="flex-shrink-0 mr-2">
                  <svg viewBox="0 0 20 20" fill="currentColor" className="h-8 w-8 text-red-500">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                  </svg>
              </div>
              <p className="text-lg leading-8 font-medium text-gray-900">
Extraits vidéos pour les réseaux sociaux Photos HD
              </p>
          </li>

          <li className="mt-4 flex items-start">
              <div className="flex-shrink-0 mr-2">
                  <svg viewBox="0 0 20 20" fill="currentColor" className="h-8 w-8 text-red-500">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                  </svg>
              </div>
              <p className="text-lg leading-8 font-medium text-gray-900">
Photos panoramiques 360°
              </p>
          </li>

          <li className="mt-4 flex items-start">
              <div className="flex-shrink-0 mr-2">
                  <svg viewBox="0 0 20 20" fill="currentColor" className="h-8 w-8 text-red-500">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                  </svg>
              </div>
              <p className="text-lg leading-8 font-medium text-gray-900">
Visite guidée automatique
              </p>
          </li>

          <li className="mt-4 flex items-start">
              <div className="flex-shrink-0 mr-2">
                  <svg viewBox="0 0 20 20" fill="currentColor" className="h-8 w-8 text-red-500">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                  </svg>
              </div>
              <p className="text-lg leading-8 font-medium text-gray-900">
Intégration Google Street View
              </p>
          </li>

          <li className="mt-4 flex items-start">
              <div className="flex-shrink-0 mr-2">
                  <svg viewBox="0 0 20 20" fill="currentColor" className="h-8 w-8 text-red-500">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                  </svg>
              </div>
              <p className="text-lg leading-8 font-medium text-gray-900">
Plan 3D "maison de poupée"
              </p>
          </li>

          <li className="mt-4 flex items-start">
              <div className="flex-shrink-0 mr-2">
                  <svg viewBox="0 0 20 20" fill="currentColor" className="h-8 w-8 text-red-500">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                  </svg>
              </div>
              <p className="text-lg leading-8 font-medium text-gray-900">
Plan 2D schématique
              </p>
          </li>

          <li className="mt-4 flex items-start">
              <div className="flex-shrink-0 mr-2">
                  <svg viewBox="0 0 20 20" fill="currentColor" className="h-8 w-8 text-red-500">
                    <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd"></path>
                  </svg>
              </div>
              <p className="text-lg leading-8 font-medium text-gray-900">
Nuage de points architecture
              </p>
          </li>
      </ul> 
      </div>

      <div className="mt-10 -mx-4 relative lg:mt-0 lg:row-start-1 lg:row-end-2">
        <img className="relative mx-auto" width="490" src={InfographieService} alt="Services 3D Matterport" />
      </div>
    </div>
  </div>
</div>
  );
}

export default Features;