import React, { useState } from "react";

import VideoCover from "../images/visite-virtuelle-matterport.jpg";
import VideoDemo from "../videos/Place-Des-Carmes-LONG-INTRO-480p-MP4.mp4"

function Video() {
  const [isOpen, togglePopin] = useState(false);
  return (
        <div>
            <div className="relative mx-auto w-full rounded-lg shadow-lg">
                <button className="relative block w-full rounded-lg overflow-hidden focus:outline-none focus:shadow-outline" onClick={() => togglePopin(!isOpen)} aria-label="Voir la vidéo" >
                    <img className="w-full" src={VideoCover} alt="Visite virtuelle 3D Matterport" />
                    <div className="absolute inset-0 w-full h-full flex items-center justify-center">
                        <svg className="h-20 w-20 text-red-500" fill="currentColor" viewBox="0 0 84 84">
                            <circle opacity="0.9" cx="42" cy="42" r="42" fill="white" />
                            <path d="M55.5039 40.3359L37.1094 28.0729C35.7803 27.1869 34 28.1396 34 29.737V54.263C34 55.8604 35.7803 56.8131 37.1094 55.9271L55.5038 43.6641C56.6913 42.8725 56.6913 41.1275 55.5039 40.3359Z" />
                        </svg>                
                    </div>
                </button>
            </div>
            <div onClick={() => togglePopin(!isOpen)} className={`${
                  isOpen ? `z-10 fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center` : `hidden`
                }`}>
                <div className={`${
                  isOpen ? `fixed inset-0 transition-opacity` : `hidden`
                }`}>
                    <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
                </div>

                <div className={`${
                  isOpen ? `bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-3xl sm:w-full sm:p-6` : `hidden`
                }`}>
                    <div>
                        <div className="absolute top-0 right-0 pt-4 pr-4">
                            <button onClick={() => togglePopin(!isOpen)} type="button" className="text-gray-400 hover:text-gray-500 focus:outline-none focus:text-gray-500 transition ease-in-out duration-150" aria-label="Fermer la vidéo" >
                                <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"/>
                                </svg>
                            </button>
                        </div>
                        <div className="mt-5 text-center">
                            <video controls autoPlay>
                                <source src={VideoDemo} type="video/mp4" />
                            </video>                    
                        </div>
                    </div>
            </div>
        </div>
    </div>

    );
}

export default Video;

